<template>
  <div
    v-if="isVisible"
    class="w-full max-w-[48rem] bg-white shadow p-6 md:p-9 fixed bottom-0 left-0 z-50 rounded-tr-3xl"
  >
    <Dialog v-if="isModalOpen" :on-accepted="handlePreferenceAcceptance" :on-dismiss="handleDismiss" />

    <div class="text-black text-sm">
      {{ t("reason") }}
    </div>

    <div class="mt-6 flex flex-wrap gap-3 md:gap-4 max-md:*:grow">
      <button class="btn" type="button" @click="handleAccept">
        {{ t("accept") }}
      </button>

      <button class="btn btn--outline" type="button" @click="handleRefuse">
        {{ t("refuse") }}
      </button>

      <button class="btn btn--outline" type="button" @click="openPreferences">
        {{ t("advanced") }}
      </button>
    </div>
  </div>
</template>

<script>
import Dialog from "./Dialog";
import { isCookiePreferenceSaved, fullCookieAcceptance, refuseCookies } from "~/components/Cookie/base";

export default {
  name: "HeaderCategory",
  components: {
    Dialog,
  },
  data() {
    return {
      isVisible: false,
      isModalOpen: false,
    };
  },
  mounted() {
    this.isVisible = !isCookiePreferenceSaved();
  },
  setup() {
    const { t, locale } = useI18n({
      useScope: "local",
    });
    const localePath = useLocalePath();

    return { t, locale, localePath };
  },
  methods: {
    handleAccept() {
      if(!fullCookieAcceptance()) {
        return
      }
      
      this.isVisible = false;
      window.STARTUP_GTM?.();
    },
    handleRefuse() {
      if(!refuseCookies()) {
        return
      }
      
      this.isVisible = false;
      window.STARTUP_GTM?.();
    },
    handleDismiss() {
      this.isModalOpen = false;
    },
    openPreferences() {
      this.isModalOpen = true;
    },
    handlePreferenceAcceptance() {
      this.isVisible = false;
      window.STARTUP_GTM?.();
    },
  },
};
</script>

<i18n lang="json">
{
  "fr": {
    "reason": "Nous utilisons des cookies pour améliorer votre expérience de navigation, diffuser des publicités ou des contenus personnalisés, et analyser notre trafic. En cliquant sur \"Accepter\", vous consentez à notre utilisation des cookies.",
    "accept": "Accepter",
    "refuse": "Refuser",
    "advanced": "Paramètres avancés"
  },
  "en": {
    "reason": "To provide the best experiences, we use technologies such as cookies to store and/or access device information. Consenting to these technologies will allow us to process data such as browsing behavior or unique IDs on this site. Failure to consent or withdrawal of consent may adversely affect certain features and functions.",
    "accept": "Accept",
    "refuse": "Refuse",
    "advanced": "Advanced parameters"
  }
}
</i18n>
