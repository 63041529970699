export const saveCookiePreference = (data) => {
  if(!localStorage.getItem("COOKIE_PREFERENCE_STEP_2")) {
    return false;
  }

  localStorage.setItem("COOKIE_PREFERENCE", JSON.stringify(data));

  return true
};

function deleteAllCookies() {
    document.cookie.split(';').forEach(cookie => {
        const eqPos = cookie.indexOf('=');
        const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
        document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
    });
}

export const isCookiePreferenceSaved = () => {
  if (typeof location === "undefined") {
    return true;
  }

  const cookiePreference = localStorage.getItem("COOKIE_PREFERENCE");
  const cookiePreferenceStep2 = localStorage.getItem("COOKIE_PREFERENCE_STEP_2");

  if(!cookiePreferenceStep2) {
    setTimeout(() => {
      deleteAllCookies()
      localStorage.clear();
      sessionStorage.clear();
  
      if(cookiePreference) {
        localStorage.setItem("COOKIE_PREFERENCE", cookiePreference);
      }  
      localStorage.setItem("COOKIE_PREFERENCE_STEP_2", "TRUE");  
    }, 2000)
  }

  return !!cookiePreference;
};

export const refuseCookies = () => {
  return saveCookiePreference({
    essentials: false,
    marketing: false,
    preference: false,
    statistics: false,
  });
};

export const fullCookieAcceptance = () => {
  return saveCookiePreference({
    essentials: true,
    marketing: true,
    preference: true,
    statistics: true,
  });
};

export default {
  saveCookiePreference,
  isCookiePreferenceSaved,
  refuseCookies,
  fullCookieAcceptance,
};
