<template>
  <div
    class="fixed z-[9999] top-0 left-0 bottom-0 right-0 flex flex-row items-center justify-center bg-dark-blue-700/40"
  >
    <div class="w-full max-w-[44rem] mx-4 bg-gradient-to-r from-green-700 to-green-800 md:p-10 rounded-2xl">
      <Preference
        :checked="essentials"
        :on-change="handleEssentials"
        :title="t('essentials.title')"
        :content="t('essentials.content')"
      />
      <Preference
        :checked="marketing"
        :on-change="handleMarketing"
        :title="t('marketing.title')"
        :content="t('marketing.content')"
      />
      <Preference
        :checked="preference"
        :on-change="handlePreference"
        :title="t('preference.title')"
        :content="t('preference.content')"
      />
      <Preference
        border-less
        :checked="statistics"
        :on-change="handleStatistics"
        :title="t('statistics.title')"
        :content="t('statistics.content')"
      />

      <div class="flex flex-row w-full mt-10">
        <ButtonWeb :onclick="handleAccept" klass="flex-none px-14 mr-4 bg-dark-blue-700 text-white">
          {{ t("accept") }}
        </ButtonWeb>

        <ButtonWeb :onclick="onDismiss" klass="flex-none font-medium !px-0 text-black">
          {{ t("close") }}
        </ButtonWeb>
      </div>
    </div>
  </div>
</template>

<script>
import { saveCookiePreference } from "../base";
import Preference from "./Preference.vue";
import ButtonWeb from "~/components/Buttons/ButtonWeb";

export default {
  name: "Dialog",
  data() {
    return {
      essentials: true,
      marketing: true,
      preference: true,
      statistics: true,
    };
  },
  setup() {
    const { t, locale } = useI18n({
      useScope: "local",
    });
    const localePath = useLocalePath();

    return { t, locale, localePath };
  },
  mounted() {
    document.body.classList.add("overflow-hidden");
  },
  destroyed() {
    document.body.classList.remove("overflow-hidden");
  },
  props: {
    onAccepted: {
      type: Function,
      required: true,
    },
    onDismiss: {
      type: Function,
      required: true,
    },
  },
  components: {
    Preference,
    ButtonWeb,
  },
  methods: {
    handleEssentials(val) {
      this.essentials = val;
    },
    handleMarketing(val) {
      this.marketing = val;
    },
    handlePreference(val) {
      this.preference = val;
    },
    handleStatistics(val) {
      this.statistics = val;
    },
    handleAccept() {
      const result = saveCookiePreference({
        essentials: this.essentials,
        marketing: this.marketing,
        preference: this.preference,
        statistics: this.statistics,
      });

      if(!result) {
        return;
      }

      this.$props.onAccepted();
    },
  },
};
</script>

<i18n lang="json">
{
  "fr": {
    "accept": "Accepter",
    "close": "Fermer",
    "essentials": {
      "title": "Témoins de nécessité",
      "content": "Les témoins nécessaires contribuent à rendre un site Web utilisable en activant des fonctions de base comme la navigation de page et l’accès aux zones sécurisées du site Web, le cas échéant. Le site Web ne peut pas fonctionner correctement sans ces témoins."
    },
    "marketing": {
      "title": "Témoins de marketing",
      "content": "Les témoins marketing sont utilisés pour effectuer le suivi des visiteurs à travers le site Web. Le but est d’afficher des publicités qui sont pertinentes et intéressantes pour l’utilisateur individuel et donc plus précieuses pour les éditeurs et annonceurs tiers."
    },
    "preference": {
      "title": "Témoins de préférences",
      "content": "Les témoins de préférences permettent à un site Web de retenir des informations qui modifient la manière dont le site se comporte ou s’affiche, comme votre langue préférée ou la région dans laquelle vous vous situez."
    },
    "statistics": {
      "title": "Témoins de statistiques",
      "content": "Les témoins statistiques aident les propriétaires du site Web, par la collecte et la communication d’informations de manière anonyme, à comprendre comment les visiteurs interagissent avec les sites Web."
    }
  },
  "en": {
    "accept": "Accept",
    "close": "Close",
    "essentials": {
      "title": "Essential Cookies",
      "content": "These cookies enable core functionality such as security, verification of identity and network management. These cookies can’t be disabled."
    },
    "marketing": {
      "title": "Témoins de nécessité",
      "content": "Les témoins marketing sont utilisés pour effectuer le suivi des visiteurs à travers le site Web. Le but est d’afficher des publicités qui sont pertinentes et intéressantes pour l’utilisateur individuel et donc plus précieuses pour les éditeurs et annonceurs tiers."
    },
    "preference": {
      "title": "Témoins de préférences",
      "content": "Les témoins de préférences permettent à un site Web de retenir des informations qui modifient la manière dont le site se comporte ou s’affiche, comme votre langue préférée ou la région dans laquelle vous vous situez."
    },
    "statistics": {
      "title": "Témoins de statistiques",
      "content": "Les témoins statistiques aident les propriétaires du site Web, par la collecte et la communication d’informations de manière anonyme, à comprendre comment les visiteurs interagissent avec les sites Web."
    }
  }
}
</i18n>
